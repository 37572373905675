<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="booleanNoYes"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { willRelationshipHelpers } from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import { willPeopleHelpers } from "../../../../fieldsets/will/helpers/willPeopleHelpers";
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { general } from "../../../options/general";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'SeparateTrustees',
  mixins: [questionLoadHelpers, willPeopleHelpers, willRelationshipHelpers, general],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: Boolean,
      required: false
    },
    callbackFunction: {
      type: Function,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Will those named as Executors also be your trustees?',
        subTitle: 'Executors deal with the administration of an estate and Trustees look after assets until they can be distributed. Your Executors could also be your Trustees, although it may be preferable to give a separate group of people the specific responsibility of safeguarding and investing assets.',
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: 'separateTrustees',
        formPath: 'data.trustees.details.separateTrustees',
        jsonSaveField: 'separateTrustees',
        callbackFunction: this.callbackFunction
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false
    }
  }
}
</script>
