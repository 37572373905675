<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Trustees</h3>

    <SeparateTrustees
        v-model="form.data.trustees.details.separateTrustees"
        :details="form.data.trustees.details"
        :callbackFunction="enable"
    />

    <template v-if="details.separateTrustees">

      <PartnerPrimaryYN
          v-if="partner"
          v-model="form.data.trustees.details.primaryPartner"
          :callbackFunction="syncOptions"
      />

      <template v-if="([true, false].includes(details.primaryPartner) || !partner)">

        <div
            v-if="primaryRequired"
            class="question-box with-arrow"
        >

          <PrimaryTrustees
              v-model="form.data.trustees.details.primary"
              :details="form.data.trustees.details"
              :optionsData="primaryOptions"
              :add-person-function="addUnderAgeCondition"
          />

        </div>

        <SecondaryYN
            v-if="details.primary.length || details.secondary.length"
            v-model="form.data.trustees.details.secondaryYN"
            :details="form.data.trustees.details"
            :callbackFunction="clearSecondary"
        />

        <template v-if="secondaryRequired">

          <div v-if="details.primary.length || details.secondary.length"
               class="question-box with-arrow">

            <SecondaryTrustees
                v-model="form.data.trustees.details.secondary"
                :details="form.data.trustees.details"
                :optionsData="secondaryOptions"
                :add-person-function="addUnderAgeCondition"
            />
          </div>
        </template>

        <BackupYN
            v-if="details.secondary.length || details.backup.length"
            v-model="form.data.trustees.details.backupYN"
            :details="form.data.trustees.details"
            :callbackFunction="clearBackup"
        />

        <template v-if="backupRequired">
          <div v-if="details.secondary.length || details.backup.length"
               class="question-box with-arrow">
            <BackupTrustees
                v-model="form.data.trustees.details.backup"
                :details="form.data.trustees.details"
                :optionsData="secondaryOptions"
                :add-person-function="addUnderAgeCondition"
            />
          </div>
        </template>
      </template>
    </template>

  </div>
</template>

<script>
import {willPeopleHelpers} from "./helpers/willPeopleHelpers";
import {fieldsetHelpers} from "../fieldsetHelpers";
import {willTrusteesHelpers} from "./helpers/willTrusteesHelpers";
import {willConditionHelpers} from "@/components/common/questionnaires/fieldsets/will/helpers/willConditionHelpers";
import {clone} from "@/mixins/clone";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import PartnerPrimaryYN from "../../question/questions/will/trustees/PartnerPrimaryYN";
import PrimaryTrustees from "../../question/questions/will/trustees/PrimaryTrustees";
import SecondaryTrustees from "../../question/questions/will/trustees/SececondaryTrustees";
import BackupTrustees from "../../question/questions/will/trustees/BackupTrustees";
import SecondaryYN from "../../question/questions/will/trustees/SecondaryYN";
import BackupYN from "../../question/questions/will/trustees/BackupYN";
import SeparateTrustees from "../../question/questions/will/trustees/SeparateTrustees";

export default {
  name: 'Trustees',
  mixins: [
    fieldsetHelpers,
    willPeopleHelpers,
    clone,
    saveHelpers,
    willTrusteesHelpers,
    willConditionHelpers
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    SeparateTrustees,
    BackupYN,
    SecondaryYN,
    BackupTrustees,
    SecondaryTrustees,
    PrimaryTrustees,
    PartnerPrimaryYN
  },
  mounted () {
    // refresh people on mount if will instruction has children section
    this.$store.dispatch('peopleFetch')
  },
  data() {
    return {
      label: 'Trustees', // used for steps progress
      primaryOptions: [],
      secondaryOptions: [],
      backupOptions: [],
      db: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.trustees.details'
      },
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
