import {dateFormat} from "@/mixins/dateFormat";
import {textHelpers} from "@/mixins/textHelpers";

export const willConditionHelpers = {
    mixins: [dateFormat, textHelpers],
    methods: {
        addUnderAgeCondition(person) {
            if (this.isUnderAge(person.dob, 18)) {
                person.condition = ` ${this.himHer(person.gender)} attaining the age of 18 years old`
            }
            return person
        }
    }
}
