<template>
  <QuestionBase :id="db.saveField"

                :errors="[...jsErrors]"
                :question="question"
                :valid="!!data.length">
    <div class="accordion sub-items">

      <!-- Selected People -->
      <transition-group name="fade">
        <ObjectCard v-for="(person, index) in data"
                    :id="'trusteePrimaryData' + index"
                    :key="'trusteePrimaryData' + person.id"
                    :ref="'trusteePrimaryData' + index"
                    :select-mode="true"
                    :selected="true"
                    :show-delete="false"
                    :title="cardTitle(person)"
                    :value="data[index]"
                    type="trusteePrimaryData"
                    :loading="loading"
                    @click="deselectConfirm(person, 'Remove person from your primary Trustees?')"
                    @delete="deletePerson(person.id)"
                    @save="savePerson( null, 'trusteePrimaryData'+index)">

          <PersonWill v-if="person.type === 'person'"
                      :key="'trusteePrimary-form-data' + person.id"
                      v-model="data[index]"
                      :errors-post="errorsPatch"
                      :no-save="true"
                      :objectId="person.id"
                      @save="savePersonForm($event, person)" />
        </ObjectCard>
      </transition-group>
    </div>

    <!-- New People -->
    <transition name="fade">
      <b-button class="btn-question w-100"
                @click="show.addPerson=true">
        <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Trustee
      </b-button>
    </transition>

    <WillPeopleModal
        v-model="show.addPerson"
        title="Add Primary Trustees"
        :dataSelected="data"
        :sub-title="'Select your trustees by selecting existing people or adding new people. You can select multiple individuals for this role.'"
        :hide-type-options="true"
        :options="optionsData"
        :show-charities="false"
        :show-groups="false"
        :add-person-function="addPersonFunction"
        @dataSelectedUpdate="data=$event"
        @save="save" />

  </QuestionBase>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "../../objects/peopleSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import { willPeopleObjectHelpers} from "../helpers/willPeopleObjectHelpers";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import PersonWill from "../../../../fieldsets/sub/people/PersonWill";
import WillPeopleModal from "../helpers/WillPeopleModal";

export default {
  name: 'PrimaryTrustees',
  components: {
    WillPeopleModal,
    PersonWill,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    willPeopleObjectHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    details: {
      type: Object,
      required: true
    },
    optionsData: {
      type: Array,
      required: true
    },
    addPersonFunction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: 'primaryTrustees',
        formPath: 'data.trustees.details.primary',
        jsonSaveField: 'primary'
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'You must have more than one Primary Trustee, click to select'
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false
      }
    }
  },
  computed: {
    question() {
      return {
        title: this.data && this.data.length === 1 ? 'Primary Trustee' : 'Primary Trustees',
        subTitle: this.details.primaryPartner ? null : 'Please confirm who you would like to act as the primary trustees. To deselect a person, simply click on their name.',
        tip: null
      }
    },
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
