import {willPeopleHelpers} from "./willPeopleHelpers";
import {willRelationshipHelpers} from "./willRelationshipHelpers";
import {willProfessionalOptions} from "./willProfessionalOptions";

export const willTrusteesHelpers = {
    // required a details computed property: only to be used in root level Trustees file
    mixins: [willPeopleHelpers, willRelationshipHelpers, willProfessionalOptions],
    mounted() {
        // calculate people options
        this.setOptionsData()

        let attributeAdded = false
        // for old created will insert new fields into data
        if (!('separateTrustees' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'separateTrustees', null)
            attributeAdded = true
        }
        if (!('secondaryYN' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'secondaryYN', null)
            attributeAdded = true
        }
        if (!('backupYN' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'backupYN', null)
            attributeAdded = true
        }
        if (attributeAdded) this.save()
    },
    watch: {
        // 'details.separateTrustees': {
        //     immediate: false,
        //     deep: false,
        //     handler(value) {
        //         this.enable(value)
        //     }
        // },
        // 'details.primaryPartner': {
        //     immediate: false,
        //     deep: false,
        //     handler() {
        //         this.syncOptions()
        //     }
        // },
        // 'details.secondaryYN': {
        //     immediate: false,
        //     deep: false,
        //     handler (value) {
        //         if (value === false) {
        //             this.clearSecondary()
        //         }
        //     }
        // },
        // 'details.backupYN': {
        //     immediate: false,
        //     deep: false,
        //     handler (value) {
        //         if (value === false) {
        //             this.clearBackup()
        //         }
        //     }
        // },
        storePeople: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        },
        details: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        }
    },
    computed: {
        primaryRequired () {
            return ([true, false].includes(this.details.primaryPartner) && this.partner) || !this.partner
        },
        secondaryRequired() {
            return this.details.secondaryYN
        },
        backupRequired() {
            return this.details.backupYN
        },
        details() {
            return this.form.data.trustees.details
        },
        data() {
            return this.form.data.trustees.details
        },
        storePeople() {
            return this.$store.getters.people
        }
    },
    methods: {
        async syncOptions() {
            this.clearAllTrustees()
            await this.addPartner()
            this.save()
        },
        async addPartner() {
            // add partner to primary trustees
            if (this.partner) {
                // find partner in primary trustees
                let partnerPrimaryIndex = this.details.primary.findIndex(client => client.id === this.partner.id)
                if (partnerPrimaryIndex >= 0 && !this.details.primaryPartner) {
                    // remove partner
                    this.details.primary.splice(partnerPrimaryIndex, 1)
                    return true
                } else if (this.details.primaryPartner) {
                    // add partner
                    this.details.primary.push(this.convertPerson(this.partner))
                    // remove partner from secondary and backup
                    let partnerSecondaryIndex = this.details.secondary.findIndex(client => client.id === this.partner.id)
                    let partnerBackupIndex = this.details.backup.findIndex(client => client.id === this.partner.id)
                    if (partnerSecondaryIndex >= 0) this.details.secondary.splice(partnerSecondaryIndex, 1)
                    if (partnerBackupIndex >= 0) this.details.backup.splice(partnerBackupIndex, 1)
                    return true
                }
            } else {
                return false
            }
        },
        clearAllTrustees() {
            this.details.primary = []
            this.details.secondary = []
            this.details.backup = []
        },
        clearSecondary() {
            if (this.details.secondaryYN !== false) return
            this.details.secondary = []
            this.save()
        },
        clearBackup() {
            if (this.details.backupYN !== false) return
            this.details.backup = []
            this.save()
        },
        enable () {
            if (this.details.separateTrustees === false) {
                this.clearAllTrustees()
                this.details.enabled = false
                this.details.primaryPartner = null
                this.details.secondaryYN = null
                this.details.backupYN = null
                this.save()
            } else {
                this.details.enabled = true
                this.save()
            }
        },
        setOptionsData() {
            // client_people from server with originally selected people filtered out,
            // mapped to be compatible with a Will then people relationships converted
            let serverPrimary = []
            let serverSecondary = []
            let serverBackup = []

            // convert people
            let serverPeople = this.storePeople.map(person => {
                // do not convert professional as they have been added from TypeOfTrustees in final form for will
                if (person.type === 'professional') return person
                // convert ClientPerson model into will person
                return this.convertPerson(person)
            })

            // filter out primary, secondary or backup options
            let primaryIds = this.details.primary.map(person => person.id)
            let secondaryIds = this.details.secondary.map(person => person.id)
            let backupIds = this.details.backup.map(person => person.id)
            serverPrimary = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id) && !backupIds.includes(person.id))
            serverSecondary = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id) && !backupIds.includes(person.id))
            serverBackup = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id) && !backupIds.includes(person.id))

            // return
            this.primaryOptions = [...serverPrimary].sort((a, b) => a.id - b.id)
            this.secondaryOptions = [...serverSecondary].sort((a, b) => a.id - b.id)
            this.backupOptions = [...serverBackup].sort((a, b) => a.id - b.id)
        },
    }
}
